:root {
    /*--primary: #E19F0D;*/
    /* rgb(255, 219, 13) */
    --primary: rgb(255, 219, 13);
    --secondary: #EABC56;
    --primary-text: rgba(0, 0, 0, .5);
}

html { scroll-behavior: smooth; }

a {
    text-decoration: none;
    color: white;
}

a:hover > button > svg {
    color: black;
    fill: black;
    transition: all .25s ease-in-out;
}

#mini-banner:hover {
    color: white;
}

.navbar-toggler {
    border: 1px solid white;
    padding: .5rem;
}

.navbar-light {
    background: black;
    padding: 1rem;
    /*background: black !important;*/
}

.nav-item > a {
    color: white;
    font-weight: bold;
}

.nav-item:hover > a {
    color: var(--primary);
}

.button-spacing-right {
    margin-right: 3rem;
}

/* Navbar */
.nav-item {
    padding: 0 1.5rem;
}

.nav-link {
    font-weight: bold;
    color: black;
}

.carousel-caption {
    background: rgba(0, 0, 0, 0.7);
    border-radius: 5rem;
}

#Layer_2 {
    fill: rgba(255, 255, 255, 0.4);
}

.landing-svg-placement {
    top: -15%;
}

.electric-icon-svg {
    display: inline-block;
    padding: .5rem;
    height: 2.5rem;
    width: 2.5rem;
    text-align: center;
    border-radius: 5rem;
    margin-right: 1rem;
    background: rgba(255, 255, 255, 0.6);
}

.electric-icon-svg-alt {
    display: inline-block;
    padding: .5rem;
    height: 2.5rem;
    width: 2.5rem;
    text-align: center;
    border-radius: 5rem;
    margin-right: 1rem;
    color: black;
    background: var(--primary);
}

.heading-font-1 {
    font-size: 4.5rem;
    font-weight: bolder;
    line-height: 5.5rem;
}

.font-color-w65{
    color: rgba(255, 255, 255, 0.75);
    -webkit-text-stroke: .05rem rgba(0, 0, 0, 0.35);
    text-stroke: .05rem rgba(0, 0, 0, 0.35);
}

.btn-primary {
    padding: 1rem 2.5rem !important;
    background: black;
    border-color: transparent;
    border-radius: 2.5rem;
}

.btn-primary-alt {
    padding: 1rem 2.5rem !important;
    background: var(--primary);
    font-weight: bold;
    border-color: transparent;
    border-radius: 2.5rem;
}

.btn-primary:hover {
    background: white;
    color: black;
    border-color: transparent;
    transition: .25s all ease-in;
}

.btn-secondary {
    padding: 1rem 2.5rem !important;
    border-color: transparent;
    border-radius: 2.5rem;
}

.margin-spacing-t-1 {
    margin-top: 15rem;
}

.margin-spacing-t-2 {
    margin-top: 6rem;
}

.margin-spacing-b-1 {
    margin-bottom: 10rem;
}

.mt-adjusted {
    margin-top: 2rem;
}

.text-highlight {
    padding: .5rem 1rem;
    background: var(--primary);
}

.line-spacing-1 {
    /*line-height: 2.5rem;*/
    line-height: 2rem;
}

.contact-banner-img {
    height: 10rem;
    object-fit: cover;
    object-position: center;
    width: 100%;
}

.contact-banner-container {
    background: black;
    border: 1px solid black;
    color: white;
}


.carousel-control-prev-icon, .carousel-control-next-icon {
    background: var(--primary);
    border-radius: 2.5rem;
}

.contact-container {
    background: var(--primary);
    height: 33rem;
    margin-bottom: 0;
}

/* Gradient Related */

.gradient-pattern {
    height: 55rem;
    animation: gradient 15s ease-in-out infinite;
    /*background-image: linear-gradient(135deg, var(--secondary), var(--primary));*/
    /*background: rgb(255, 219, 13)*/
    /*        url('./assets/Solar/13-3bd73e57-024a-4cf9-a7f8-09ac008ed73a.jpg') no-repeat;*/
    background-image:
            linear-gradient(to bottom, rgba(255, 219, 13, .75), rgba(234, 188, 86, 0.65)),
            url('./assets/Solar/13-3bd73e57-024a-4cf9-a7f8-09ac008ed73a.jpg');
    background-size: 105% 105%;
    /*background-size: 400% 400%;*/
    width: 100%;
    overflow: clip;
}

@keyframes gradient {
    0% {
        background-position: 51% 0%;
    }
    50% {
        background-position: 50% 100%;
    }
    100% {
        background-position: 51% 0%;
    }
}

@media only screen and (max-width: 768px) {
    .contact-container {
        height: 50rem;
    }

    .contact-container h1 {
        font-size: 3rem;
    }

    .heading-font-1 {
        font-size: 3rem;
        line-height: 4rem;
    }

    .carousel-caption > h5 {
        font-size: 1rem;
    }

}